<script>
	import { page } from '$app/stores';
	import { Button } from 'carbon-components-svelte';
	import { PARENT_INFO } from '$lib/stores/parent';
</script>

<svelte:head>
	<title>SchoolMo | Error</title>
</svelte:head>

<div class="error-wrapper">
	<div class="error">
		<span class="status">{$page.status}</span>
		<div class="message">
			<h1>{$page.error.message}</h1>
		</div>
	</div>

	<Button href={$PARENT_INFO ? '/parents/students' : '/'} kind="ghost">Go Back</Button>
</div>

<style>
	.error-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		flex-direction: column;
		gap: 2rem;
	}

	.error {
		display: flex;
		align-items: center;
		max-width: 32rem;
		margin: 0 1rem;
	}

	.status {
		font-weight: 200;
		font-size: 3rem;
		line-height: 1;
		position: relative;
		top: -0.05rem;
	}

	.message {
		border-left: 1px solid #ccc;
		padding: 0 0 0 1rem;
		margin: 0 0 0 1rem;
		min-height: 2.5rem;
		display: flex;
		align-items: center;
	}

	.message h1 {
		font-weight: 400;
		font-size: 1em;
		margin: 0;
	}
</style>
